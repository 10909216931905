import React, { createContext, useContext } from 'react';
import { useQuery, gql } from '@apollo/client';

import { LoginPage } from '@/pages/LoginPage';
import { LoadingState } from '@/components/LoadingState';

const queryStore = gql`
  {
    currentStore {
      timezone
      profilesCount
      package
      hasValidPlan
      onTrialPlan
      supportedFields
      supportedOrderFields
      supportedCustomerFields
      supportedProductMetafieldsFields
      supportedProductReviewsFields
      supportedGiftCertsFields
      supportedDataFeedFields
      supportedFileTypes
      supportedDataFeedFileTypes
      supportedColumnSeparators
      supportedExportMethods
      storeCategories
      storeBrands
      planVersion
      isPayingCustomer
      paymentProvider
      totalProducts
      totalVariants
      trialExpiryDate
      supportHourlyInterval
      supportFifteenminsInterval
      supportThirtyminsInterval
      supportWebhook
      platform
      publicToken
      supportPublicUrl
      supportExportYearly
    }
  }
`;

const StoreContext = createContext();

const StoreProvider = ({ children }) => {
  const { loading, error, data } = useQuery(queryStore);

  let elem;
  if (loading) return <LoadingState />;

  if (data) {
    elem = (
      <StoreContext.Provider
        value={{
          currentStore: data.currentStore,
        }}
      >
        {children}
      </StoreContext.Provider>
    );
  } else {
    elem = <LoginPage />;
  }
  return elem;
};

export { StoreProvider, StoreContext };
